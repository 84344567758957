<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("menu.input") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      checkbox-color="primary"
      :headers="headers"
      :items="inputs"
      :search="search"
      v-model="selectedItems"
      :show-select="showCheckBox"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalFromServer"
      :no-results-text="$t('general.ui.not_search_found')"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row class="d-flex justify-space-between align-center">
            <v-col class="pl-0" lg="4" md="4" sm="12" xl="4">
              <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceSearch" v-on:keyup.ctrl.86="replaceSearch"
                dense
                filled
                rounded
                color="primary"
                v-model="search"
                @keypress.enter="searchForText"
                @click:clear="searchAll"
                @click:append="searchForText"
                append-icon="mdi-magnify"
                :label="$t('general.titles.filter')"
                :clearable="true"
                single-line
                hide-details
              />
            </v-col>
            <v-col lg="4" md="4" sm="12" xl="4">
              <v-btn
                small
                tile
                :elevation="0"
                color="gray"
                style="border-radius: 1.5em;"
                @click="checkBoxShow"
                class="mr-1"
                :disabled="!(inputs && inputs.length > 0)"
              >
                <span v-if="!showCheckBox">
                  <v-icon small color="neuter" left
                    >mdi-checkbox-multiple-marked-outline</v-icon
                  >
                  <span class="neuter--text">{{
                    $t("general.buttons.checksBoxEnable")
                  }}</span>
                </span>
                <span v-else>
                  <v-icon small color="neuter" left
                    >mdi-checkbox-multiple-marked</v-icon
                  >
                  <span>{{ $t("general.buttons.checksBoxDisable") }}</span>
                </span>
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              lg="4"
              md="4"
              sm="8"
              xl="4"
              class="text-end d-flex justify-end"
            >
              <v-btn
                small
                tile
                :elevation="0"
                color="gray"
                style="border-radius: 1.5em 0 0 1.5em;"
                @click="exportCSVInput"
                v-if="check([{ domain: 'Input', permisions: ['Write'] }])"
                :loading="loadingExport"
                class="mr-1"
              >
                <v-icon small color="neuter" left
                  >mdi-download-multiple</v-icon
                >
                <span style="color:neuter">{{
                  $t("general.buttons.export")
                }}</span>
                <template v-slot:loader>
                  <v-progress-circular
                    style="position: absolute;
                                                   margin-left: -3px;
                                                   height: 15px;"
                    color="secondary accent-4"
                    indeterminate
                    rounded
                  />
                </template>
              </v-btn>

              <v-dialog persistent v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    tile
                    :elevation="0"
                    color="gray"
                    style="border-radius: 0 1.5em 1.5em 0;"
                    v-if="check([{ domain: 'Input', permisions: ['Write'] }])"
                    v-on="on"
                  >
                    <v-icon small color="neuter" left>mdi-plus</v-icon>
                    {{ $t("general.buttons.new") }}
                  </v-btn>
                </template>
                <v-card :light="true" color="white">
                  <v-card-title class="pa-6">
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="px-6">
                    <v-container>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-col cols="12" class="d-flex justify-end">
                            <v-switch
                              v-model="editedItem.active"
                              color="primary"
                            >
                              <template v-slot:prepend>
                                <v-label ref="label"
                                  >{{ $t("input.fields.active") }}
                                </v-label>
                              </template>
                            </v-switch>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field outlined dense onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceInputName" v-on:keyup.ctrl.86="replaceInputName"
                              color="primary"
                              v-model="editedItem.input_name"
                              required
                              class="required"
                              :rules="requireAndMaxRules(max)"
                              :counter="max"
                              maxlength="max"
                            >
                              <template v-slot:label>
                                  {{$t('input.fields.name')}} <strong class="red--text">*</strong>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              outlined
                              dense
                              @keypress="preventQuote"
                              v-on:keyup.86="replaceCode"
                              v-on:keyup.ctrl.86="replaceCode"
                              color="primary"
                              v-model="editedItem.code"
                              required
                              class="required"
                              type="number"
                              @input="clearPoints($event, editedItem)"
                              @keyup="validGtin14($event, editedItem)"
                              @keydown="validGtin14Delete($event, editedItem)"
                              :rules="requireAndGTIN14Rules(13)"
                            >
                              <template v-slot:label>
                                {{$t('input.fields.codeGTIN')}} <strong class="red--text">*</strong>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea outlined dense onkeypress="return (event.charCode != 34)"
                            v-on:keyup.86="replaceDescriptionText" v-on:keyup.ctrl.86="replaceDescriptionText"
                              v-model="editedItem.description_text"
                              color="primary"
                              :rules="
                                lengthRules(250).concat(limitEnterKeyPress)
                              "
                              :auto-grow="true"
                              :clearable="true"
                              :label="$t('input.fields.description')"
                              :no-resize="true"
                              :row-height="40"
                              :rows="3"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <upload-evidence :nomenclator="editedItem" :loading="loadingEvidences" />
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="pa-6">
                    <v-spacer></v-spacer>
                    <v-btn small text color="neuter" @click="close">
                      {{ $t("general.buttons.cancel") }}
                    </v-btn>
                    <v-btn
                      small elevation="0" color="primary" class="t-bw-primary--text"
                      :disabled="!valid"
                      @click="save"
                    >
                      {{ $t("general.buttons.save") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              small
              class="mr-2"
              @click="showItem(item)"
              v-if="check([{ domain: 'Input', permisions: ['Update'] }])"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.show") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.input") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="check([{ domain: 'Input', permisions: ['Update'] }])"
              color="neuter"
              class="mr-2"
              small
              @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.edit") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.input") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="check([{ domain: 'Input', permisions: ['Delete'] }])"
              color="neuter"
              small
              @click="deleteItemDialog(item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.delete") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.input") }}
            </span>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-icon
          small
          class="mr-4"
          color="success"
          v-if="item.active"
        >
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon small class="mr-4" color="disabled" v-else>
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>
      <template v-slot:item.description_text="{ item }">
        <div
          class="mr-4"
          v-if="item.description_text.toUpperCase() === 'NULL'"
        >
          -
        </div>
        <div class="mr-4" v-else style="width: 200px; overflow-wrap: break-word;">
          <ReadMore
            :classes="'mb-0'"
            :font-size="14"
            :max-chars="50"
            :text="item.description_text"
          />
        </div>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog
        v-if="editedItem"
        v-model="showItemDetailsDialog"
        persistent
        width="600px"
        scrollable
      >
        <v-form ref="form" v-model="valid">
          <v-card>
            <v-card-title class="px-6"
              style="display: flex; justify-content: space-between; align-content: center; align-items: flex-start;"
            >
              {{ editedItem.input_name }}
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-subtitle class="px-6">
              <div style="display: flex">
                <p class="mr-2">{{ $t("input.fields.codeGTIN") }}:</p>
                {{ editedItem.code }}
              </div>
            </v-card-subtitle>

            <v-divider light></v-divider>
            <v-card-text class="pa-6">
              <div class="row">
                <div class="col-12">
                  <div class="primary"
                    style=" border-radius: 5px; padding:7px; text-align: start;"
                  >
                    <h3 style="padding-left: 5px;" class="t-bw-primary--text">
                      {{ $t("input.fields.description") }}
                    </h3>
                  </div>
                  <div
                    class="mt-2 dialog-box__content"
                  >
                    {{ editedItem.description_text || '-' }}
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-divider light></v-divider>
            <v-card-actions class="pa-6">
              <v-btn
                icon
                fab
                v-if="editedItem.evidences && editedItem.evidences.length > 0"
                text
                :loading="loadingDownloadEvidences"
                :disabled="loadingDownloadEvidences"
                small
                @click="downloadEvidence(editedItem)"
              >
                <v-icon color="neuter">
                  mdi-file-download-outline
                </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn class="t-bw-primary--text"
                     elevation="0"
                     color="primary"
                     small @click="close">
                {{ $t('general.buttons.close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{ title }}</v-card-title>
          <v-card-text class="pa-6">{{ dialogText }}</v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color="neuter" @click="dialogClose">
              {{ $t("general.buttons.cancel") }}
            </v-btn>
            <v-btn small elevation="0" color="error" class="t-bw-error--text" @click="deleteItem">
              {{ $t("general.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import PermisionService from "../../../services/PermisionService";
import LanguajeService from "../../../services/LanguajeService";
import MoreOptions from "../../../components/MoreOptions";
import InputService from "../../../services/InputService";
import UploadEvidence from "../../../components/UploadEvidence";
import ReadMore from "@/components/ReadMoreComponent.vue";

const PROFILE = JSON.parse(localStorage.getItem('profile'));

export default {
  components: {ReadMore, UploadEvidence, MoreOptions },
  data: () => ({
    menu: false,
    langs: [],
    profile: null,
    dialog: false,
    editedIndex: -1,
    search: "",
    editedItem: {
      input_name: "",
      code: "",
      description_text: "",
      active: true,
      evidences: [],
    },
    defaultItem: {
      active: true,
    },
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [10, 20, 50, 100, 200],
    },
    showDialog: false,
    showItemDetailsDialog: false,
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    valid: false,
    max: 60,
    deletingItem: {},
    showCheckBox: false,
    selectedItems: [],
    selectedItem: null,
    isEnabledCheckBox: false,
    loadingDownloadEvidences: false,
    loadingEvidences: false,
    loadingExport: false,
    options: {},
    defaultFilters: {
      companyId: PROFILE.company_id,
      search: "",
      pageableDTO: {
        page: 0,
        size: 10,
        sortBy: 'id',
        direction: 'ASC',
      }
    }
  }),

  computed: {
    optionsTable: {
      get() {
        return this.$store.getters['ui/GET_OPTIONS_TABLE']
      },
      set(val) {
        this.$store.dispatch('ui/SET_OPTIONS_TABLE', val)
      },
    },
    notification: {
      get() {
        return this.$store.getters["ui/GET_NOTIFICATION"];
      },
      set(val) {
        this.$store.commit("ui/NOTIFICATION", val);
      },
    },
    ...mapGetters({
      inputs: "input/inputs",
      totalFromServer: "input/totalFromServer",
      evidences: "documents/evidences",
      loading: "input/loading",
      requireAndMaxRules: "general/requireAndMaxRules",
      lengthRules: "general/lengthRules",
      requireAndGTIN14Rules: "general/requireAndGTIN14Rules",
      requireRules: "general/requireRules",
      languageStructure: "general/languageStructure",
      limitEnterKeyPress: "general/limitEnterKeyPress",
      equalObjectsCompareRule: "general/equalObjectsCompareRule"
    }),
    config: {
      get() {
        return this.$store.getters['general/config']
      },
      set(val) {
        this.$store.commit('general/SET_CONFIG', val)
      }
    },
    decimalFormat() {
      return this.config.company.traceability.structure.number.afterPoint !== null
          ? this.config.company.traceability.structure.number.afterPoint
          : 3
    },
    headers: () => [
      { text: i18n.t("input.fields.name"), value: "input_name" },
      { text: i18n.t("input.fields.codeGTIN"), value: "code" },
      { text: i18n.t("input.fields.description"), value: "description_text" , sortable: false},
      { text: i18n.t("input.fields.active"), value: "active", align: "center" },
      {
        text: i18n.t("general.fields.action"),
        value: "action",
        width: 120,
        sortable: false,
        align: "center",
      },
    ],
    formTitle() {
      return (
        (this.editedIndex === -1
          ? i18n.t("general.titles.new")
          : i18n.t("general.titles.edit")) +
        " " +
        i18n.t("input.titles.new_input")
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      async handler() {
        this.optionsTable = this.options
        await this.searchText()
      },
      deep: true,
    },
  },

  created() {
    this.config = JSON.parse(localStorage.getItem('config'))
    this.initialize();
  },

  methods: {
    ...mapActions({
      fetchListInputs: "input/fetchListInputs",
      saveInput: "input/saveInput",
      deleteInput: "input/deleteInput",
      clearEvidences: "documents/clearEvidences",
      exportCSV: "input/exportCSV",
    }),
    async searchAll() {
      let filters = {
        companyId: null,
        search: null,
        pageableDTO: {
          page: 0,
          size: 10,
          sortBy: 'id',
          direction: 'DESC',
        }
      }

      filters.companyId = this.profile.company_id

      await this.fetchListInputs([filters, this.$toast])
    },
    async searchForText() {
      this.options.page = 1
      await this.searchText()
    },
    async searchText() {
      let filters = {
        companyId: null,
        search: null,
        pageableDTO: {
          page: null,
          size: null,
          sortBy: 'id',
          direction: 'DESC'
        }
      }

      filters.companyId = this.profile.company_id

      const direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
              ? 'asc'
              : 'desc'
      const sort =
          this.options.sortBy.length === 0
              ? 'id'
              : _.filter(this.headers, { value: this.options.sortBy[0] })[0].value
      filters.pageableDTO = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sortBy: sort,
        direction: direction,
      }

      if (this.search && this.search !== '')
        filters.search = this.search

      await this.fetchListInputs([filters, this.$toast])
    },
    checkBoxShow() {
      this.showCheckBox = !this.showCheckBox;
      if (!this.showCheckBox) this.selectedItems = [];
    },
    getByProperty(array, property) {
      return _.map(array, property);
    },
    async exportCSVInput() {
      this.loadingExport = true;
      const data = {
        language: LanguajeService.getLenguajeName(),
        inputs_ids: this.getByProperty(this.selectedItems, "id"),
      };
      await this.exportCSV([data, this.$toast]).finally(() => {
        this.loadingExport = false;
      });
    },
    initialize() {
      this.profile = JSON.parse(localStorage.getItem("profile"));
      this.fetchListInputs([this.defaultFilters, this.$toast]);
    },
    async showItem(item) {
      this.selectedItem = item;
      this.editedIndex = this.inputs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.evidences && item.evidences.length > 0) {
        this.getEvidences(this.editedItem);
      }
      this.showItemDetailsDialog = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.editedIndex = this.inputs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.evidences && item.evidences.length > 0) {
        this.getEvidences(this.editedItem);
      }
      this.dialog = true;
    },
    downloadEvidence(item) {
      this.loadingDownloadEvidences = true;
      InputService.getEvidences(item)
        .then((response) => {
          const FileSaver = require("file-saver");
          const blob = new Blob([response.data], {
            type: "application/zip",
          });
          FileSaver.saveAs(blob, `input_${item.input_name}_evidences.zip`);

          this.$toast.success(
            i18n.t("general.notification.successDownloadEvidences"),
            {
              icon: 'mdi-check-circle',
              queueable: true
            }
          );
        })
        .catch((error) => {
          this.$toast.error(
            i18n.t("general.notification.errorDownloadEvidences"),
            {
              icon: "mdi-alert-circle",
              queueable: true
            }
          );
        })
        .finally(() => {
          this.loadingDownloadEvidences = false;
        });
    },
    getEvidences(editedItem) {
      const scope = this;
      scope.loadingEvidences = true;
      try {
        InputService.getEvidences(editedItem)
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/zip",
            });
            const jsZip = require("jszip");
            jsZip.loadAsync(blob).then((zip) => {
              Object.keys(zip.files).forEach((filename) => {
                zip.files[filename].async("blob").then((fileData) => {
                  // These are your file contents
                  let type = "";
                  const nameSpliter = filename.split(".");
                  if (nameSpliter[1] === "pdf" || nameSpliter[1] === "PDF") {
                    type = "application/pdf";
                  } else if (
                    nameSpliter[1] === "xlsx" ||
                    nameSpliter[1] === "XLSX"
                  ) {
                    type =
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                  } else if (
                    nameSpliter[1] === "xls" ||
                    nameSpliter[1] === "XLS"
                  ) {
                    type = "application/vnd.ms-excel";
                  } else {
                    type = "image/" + nameSpliter[1];
                  }
                  scope.$store.commit(
                    "documents/SET_EVIDENCES",
                    new File([fileData], filename, { type: type })
                  );
                });
              });
            });
          })
          .finally(() => {
            scope.loadingEvidences = false;
          });
      } catch (error) {
        scope.$toast.error(
          i18n.t("general.notification.loadEvidencesError"),
          {
            queueable: true
          }
        );
      }
    },
    check(permissions) {
      return PermisionService.check(permissions);
    },
    deleteItem() {
      this.dialogClose();
      this.deleteInput([this.deletingItem.id, this.$toast]).finally(() => {
        this.fetchListInputs([this.defaultFilters, this.$toast]);
      });
    },

    close() {
      if (this.dialog === true) this.dialog = false;
      if (this.showItemDetailsDialog === true)
        this.showItemDetailsDialog = false;
      this.$refs.form.reset();
      this.clearEvidences();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (
        this.equalObjectsCompareRule(this.selectedItem, this.editedItem) &&
        this.editedItem.evidences.length === this.evidences.length
      ) {
        this.$toast.info(i18n.t("general.notification.nothingToModify"), {
          icon: 'mdi-information-slab-circle',
          queueable: true
        });
      } else {
        if (!this.editedItem.description_text)
          this.editedItem.description_text = "";

        let item = Object.assign({}, this.editedItem);
        // Translate
        item.languageKey = LanguajeService.setKey3(
          item.input_name,
          item.languageKey,
          !item.id ? this.languageStructure : null
        );
        item.description = LanguajeService.setKey3(
          item.description_text,
          item.description,
          !item.id ? this.languageStructure : null
        );
        // *****
        item.companyId = this.profile.company_id;

        this.saveInput([
          item,
          this.evidences,
          this.editedIndex,
          this.$toast,
        ]).finally(() => {
          this.fetchListInputs([this.defaultFilters, this.$toast]);
        });
        this.close();
      }
    },
    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },
    dialogClose() {
      this.showDialog = false;
    },
    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("general.titles.alert"),
        dialogText: i18n.t("general.notification.deleteItems"),
      });
    },
    getDateFormat(dateString) {
      return moment(new Date(dateString)).format("DD/MM/YYYY");
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceSearch(event){
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceInputName(event){
      this.editedItem.input_name =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceCode(event){
      this.editedItem.code =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceDescriptionText(event){
      this.editedItem.description_text =  event.currentTarget.value.toString().replaceAll('"','')
    },
    preventQuote(event) {
      if (event.charCode === 34) {
        event.preventDefault();
      }
    },
    clearPoints(event, input) {
      let text = event;
      if (text) {
        text = text.replace(/[^0-9]/g, "");
        this.$nextTick(() => {
          input.code = text;
        });
      }
    },
    validGtin14(event, input) {
      let text = input.code.toString().replace(/[^0-9]/g, "");

      this.$nextTick(() => {
        if (text.length > 13) {
          input.code = text.slice(0, -1);
        } else if (text.length === 13) {
          let sum = 0;
          for (let i = 0; i < text.length; i++) {
            let digit = parseInt(text[i], 10);
            sum += digit * ((i % 2 === 0) ? 3 : 1);
          }
          let checkDigit = (10 - (sum % 10)) % 10;
          input.code = text + checkDigit;
        }
      });
    },
    validGtin14Delete(event, input) {
      if (event.keyCode === 8) {
        this.$nextTick(() => {
          let text = input.code ? input.code.toString() : '';
          if (text.length === 14) {
            input.code = text.slice(0, -1);
          }
        });
      }
    },
  },
  destroyed() {
    this.notification = false;
  },
};
</script>

<style scoped></style>
